import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['year', 'dateField'];

  connect() {
    const year = this.yearTarget.value;
    if (year.length === 4) {
      this.updateDateRange();
    }
  }

  handleYearInput(event) {
    const year = event.target.value;
    if (year.length === 4) {
      this.updateDateRange();
    }
  }

  updateDateRange() {
    const year = this.yearTarget.value;
    const minDate = `${year}-01-01`;
    const maxDate = `${year}-12-31`;

    this.dateFieldTargets.forEach((target) => {
      const dateField = target;
      dateField.min = minDate;
      dateField.max = maxDate;
    });
  }
}
