import { Controller } from '@hotwired/stimulus';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static targets = ['amount'];

  static values = { id: Number };

  updateAmount() {
    patch(`/admin/bills/${this.idValue}`, {
      body: JSON.stringify({ bill: { amount: this.amountTarget.value } }),
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    });
  }
}
